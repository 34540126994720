.blur_everything {
  background: #e0e0e0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  z-index: 600;

  &.active {
    display: none;
  }  
}

#rectshape {
  fill: none;
  pointer-events: all;
}

.rankingbg{
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  z-index: 599;

  &.active {
    display: none;
  }
}

.rankingCard{
  background: #3b424c;
  width:68%;
  height:75%;
  top: 10%;
  left: 10%;
  position: absolute;
  color: #FFFFFF;
  max-width: 1500px;
  max-height: 2000px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    
    .rankingHeader{
      align-items: center;
        display: flex;
        position: relative;
        margin: 10px auto;
        padding: 0 10px;

        .heading { 
            margin-left: 50%;
            font-weight: 400;
            font-size: 15px;
            transform: translateX(-50%);
        }  
        .rankbox { 
            margin-left: auto;           
            display: block;
            position: relative;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
  
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
                position: absolute;
                margin-left: 99%;
                height: 15px;
                width: 15px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                border-width: 0.1em;
                border-style: solid;
                border-color: #20AAAC;
                height: 14px;
                width: 14px;
                left: 10px;

                &:hover {
                    border-color: #0000FF;
                  }
            }
        }    


        /* Create the checkmark/indicator (hidden when not checked) */
        .rankbox .checkmark:after {
            content: "";
            position: relative;
            display: none;
        }
        
        /* Show the checkmark when checked */
        .rankbox input:checked ~ .checkmark:after {
            display: block;
        }
        
        /* Style the checkmark/indicator */
        .rankbox .checkmark:after {
            left: 3px;
            top: 1px;
            width: 3px;
            height: 6px;
            border: solid #20AAAC;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .btn {      
            padding: 6px 12px;
            margin-left: auto;
            margin-bottom: 0;
            font-weight: 400;
            line-height: 1.42857143;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            cursor: pointer;
            -webkit-user-select: none;
            color: inherit;
            background-color: #20AAAC;
            &:hover {
                background-color: #FF0000;
              }
        }
    }
}
    
.mobileRankingCard{
  background: #3b424c;
  width:100%;
  height:93%;
  position: absolute;
  color: #FFFFFF;
  max-width: 1500px;
  max-height: 2000px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    
    .rankingHeader{
      align-items: center;
        display: flex;
        position: relative;
        margin: 10px auto;
        padding: 0 10px;

        .heading { 
            font-weight: 400;
            font-size: 15px;
            transform: translateX(-50%);
        }  
        .rankbox {          
            display: block;
            position: relative;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
  
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
                position: absolute;
                margin-left: 99%;
                height: 15px;
                width: 15px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                border-width: 0.1em;
                border-style: solid;
                border-color: #20AAAC;
                height: 14px;
                width: 14px;
                left: 10px;

                &:hover {
                    border-color: #0000FF;
                  }
            }
        }    


        /* Create the checkmark/indicator (hidden when not checked) */
        .rankbox .checkmark:after {
            content: "";
            position: relative;
            display: none;
        }
        
        /* Show the checkmark when checked */
        .rankbox input:checked ~ .checkmark:after {
            display: block;
        }
        
        /* Style the checkmark/indicator */
        .rankbox .checkmark:after {
            left: 3px;
            top: 1px;
            width: 3px;
            height: 6px;
            border: solid #20AAAC;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.rankingGrid{
  width: 100%;
  height: 100%;
  margin: 0 auto;
  }


#rankingrectshape {
  fill: none;
  pointer-events: all;
}

circle {
  fill: none;
  stroke-width: 2.5px;
}

.working{
    top: 25%;
    left:35%;
    height:355px;
    width:578px;
    position: absolute;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url(/working.gif) no-repeat;
  z-index: -1;
}