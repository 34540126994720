.card_half_contain {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  height: 100%;

}
.card_full_contain {
  display: grid;
  /* grid-template-rows: auto 1fr; */
  height: 100%;
  width: 100%;
}

.card_full_contain .button_contain_full {
  margin-left: auto;
  padding: 10px;
}
.card_full_contain .button_contain_full .btn{
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    color: inherit;
    border: 1px solid #e7eaec;
}

.refgrid {
  height: 85.7%;
}

.react-grid-Main {
  height: 100% !important;
  outline: none;
  background: none;
}

.news .react-grid-Main {
  height: 95% !important;
}

.mobile_top_bottom_card
{
  display: grid;
  margin-top: 5px  !important;
  grid-gap: 30px;
  height: calc(100% - 5px);
}

.mobile_layer_card
{
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  margin-top: 5px  !important;
  grid-gap: 100px;
  height: calc(100% - 5px);
}

.mobileLayer {
  height: 100%;
}

.mobileChart {
  position: relative;
  height: 100%;
  padding-left: 20%;
}

.mobilehalfTabletop {
  height: 100%;
}

.mobilehalfTablebottom {
  height: 100%;
}

.halfTableright {
  overflow: hidden;
}

.halfTableright .label{
  margin: 8px;
}

.halfTableright.two_row {
  display: grid;
  grid-template-rows: auto 1fr;
}

.halfTableright .button_contain_half {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 10px 10px 10px 0;
}

.halfTableright .button_contain_full {
  padding: 10px 10px 10px 0;
  width: 100%;
}

.halfTableright input {
  height: 24px;
  width: 100%;
  margin-right: 12px;
}

.halfTableleft {
  overflow: hidden;
}

.halfTableleft .label{
  margin: 8px;
}

.halfTableleft.two_row {
  display: grid;
  grid-template-rows: auto 1fr;
}

.halfTableleft .button_contain_full {
  width: 100%;
  padding: 10px 0 10px 10px;
}

.halfTableleft .button_contain_half {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 10px 0 10px 10px;
}

.sd_contain {
    display: flex;
  width: 100%;
  height:100%;
  overflow: auto;
}

.smallerleft {
  display: grid;
  width: 40%;
  overflow: auto;
}

.largerright {
  width: 100%;
  height:100%;
  overflow: hidden;
}


.stock_title {
  font-weight: 700;
  color: #F44336;
}

.stock_info_contain {
  padding: 0 10px 10px 10px;
}

.sd_section {
  width: 170px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  padding: 0 10px;
  overflow: hidden;
}

#StockDetailsChart {
  display: grid;
    position: relative;
    height: 100%;
    width:100%;
    font-size: 10px;
    max-height: 100%;
    max-width: 100%;
}

.sd_label{
      color: #AFAFAF;
    }

.sd_data{
      margin-left: auto;
      font-weight: 700;
      color: #F44336;
    }

.summary_value {
  color: #35d94c;
}

.react-grid-Container {
  height: 100%;
  width: 100%;
}

.holding_card .react-grid-Container{
  height: 73%;
}

.react-grid-HeaderRow {
  background: #323841;
  min-height: 0 !important;
  width: 100% !important;
  z-index: 10;
}
.react-grid-Cell {
  position: absolute;
  background-color: #000000;
  padding-left: 8px;
  padding-right: 8px;
  border-right: 1px solid #353a39;
  border-bottom: 1px solid #353a39;
  color: inherit  !important;
}
.react-grid-HeaderCell-sortable {
  padding-left: 8px;
}

.react-grid-Grid {
  border-top: none;
  background: none;
  height: 100%;
  min-height: 0 !important;
  border: none;
}

.react-grid-Canvas {
  background: none;
  background-color: rgba(0, 0, 0, 0) !important;
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  overflow: auto !important;
}

.react-grid-Viewport{
    top: 20px  !important;
    overflow: auto !important;
}

select {
  background: none;
  border-radius: 0;
  width: 100%;
  height: 24px;
  padding-left: 10px;
  -webkit-appearance: none;
  color: white;
  background-color: #323841;
}

    .Resizer {
        background: #000;
        opacity: .2;
        z-index: 1;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -moz-background-clip: padding;
        -webkit-background-clip: padding;
        background-clip: padding-box;
    }

     .Resizer:hover {
        -webkit-transition: all 2s ease;
        transition: all 2s ease;
    }

     .Resizer.horizontal {
        height: 11px;
        margin: -5px 0;
        border-top: 5px solid rgba(255, 255, 255, 0);
        border-bottom: 5px solid rgba(255, 255, 255, 0);
        cursor: row-resize;
        width: 100%;
    }

    .Resizer.horizontal:hover {
        border-top: 5px solid rgba(0, 0, 0, 0.5);
        border-bottom: 5px solid rgba(0, 0, 0, 0.5);
    }

    .Resizer.vertical {
        width: 11px;
        margin: 0 -5px;
        border-left: 5px solid rgba(255, 255, 255, 0);
        border-right: 5px solid rgba(255, 255, 255, 0);
        cursor: col-resize;
    }

    .Resizer.vertical:hover {
        border-left: 5px solid rgba(0, 0, 0, 0.5);
        border-right: 5px solid rgba(0, 0, 0, 0.5);
    }
    .Resizer.disabled {
      cursor: not-allowed;
    }
    .Resizer.disabled:hover {
      border-color: transparent;
    }

