.removeFromTable {
background: none;
border: none;
cursor: pointer;
height: 10px;
width: 10px;
color: #20AAAC;

&:hover {
  svg{
    color: #FF0000;
  }
}
svg {
  color: inherit; 
  max-width: 8px;
  transition: color 0.2s ease;
  width: 100%;
}
}
.container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
   border-width: 0.1em;
  border-style: solid;
  border-color: #20AAAC;
  height: 14px;
  width: 14px;
  left: 7px;
  
    &:hover {
        border-color: #fff;
      }
  
}
/* Style the checkmark/indicator */
.containercheck {
  display: block;
  position: relative;
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #20AAAC;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}