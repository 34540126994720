.app_header {
  align-items: center;
  background: #3b424c;
  display: flex;
  flex-wrap: wrap;
  padding: 11px;
  position:fixed;
  width: 100%;
  z-index:300;

  .logo {
    margin-right: 20px; 

    img {
      max-width: 100px;
      width: 100%;
    }
  }

  .counter_contain {
    border-left: 1px solid #464E5A;
    display: flex;
    flex-wrap: nowrap;

    @media screen and (max-width: 1025px) {
      margin-bottom: 10px;
    }
  }

  .day_section, .count_section {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 6px;

    p {
      color: #AFAFAF;
      display: flex;
      margin: 0;
      width: 100%;
    }

    .label {
      font-weight: 700;
    }

    .number {
      margin-left: auto;
    }

    .count {
      font-size: 31px;
      font-weight: 700;
    }
  }

  .day_section {
    max-width: 185px; 
    
    p {
      max-width: 155px;
    }
  }

  .count_section {
    border-left: 1px solid #464E5A;
  }

  .button_bar {
    border-left: 1px solid #464E5A;
    display: flex;
    flex-wrap: nowrap;
    padding-left: 10px;
  }

  .challengelabel {
    align-items: center;
    border-left: 1px solid #464E5A;
    display: flex;
    flex-wrap: nowrap;
    padding: 0 10px;
    color: #20AAAC;
    font-size: 15px;
    font-weight: 700;

    p {
      margin: 0;
    }
  }

  .header_button {
    align-items: center;
    background: none;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transition: color 0.2s ease;
    outline: none;

    &:hover, &:focus {
      color: #20AAAC
    }

    .button_label {
      display: block;
      flex: 1 0 100%;
      margin-top: 5px;
    }

    svg {
      flex: 1 0 100%;
      max-width: 12px;
      width: 100%;
    }

    &.dropdown_item {
      border-left: 1px solid #48504e;
      border-top: 1px solid #48504e;
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-start;
      padding: 10px;

      .button_label {
        flex: 1 0 auto;
        text-align: left;
        margin-top: 0;
      }

      svg {
        flex: 1 0 auto;
        margin-right: 10px;
      }
    }
  }

  .tab_button {
    display: flex;
    margin-left: auto;
    position: relative;
  }

  .menu_button {
    display: flex;
    position: relative;
  }

  .menu_dropdown {
    display: none;
    background: #323841;
    position: absolute;
    right: -11px; 
    top: calc(100% + 13px);
    list-style: none;
    margin: 0;
    padding: 0;
    min-width: 150px;
    z-index: 200;

    &.active {
      display: block;
    }

    li {
      &:last-child {
        border-bottom: 1px solid #48504e;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .header_button {
      padding: 0 10px;

      &.dropdown_item {
        .button_label {
          display: block;
        }
      }

      .button_label {
        display: none;
      }
    }
  }
}