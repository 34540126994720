@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'),
       local('OpenSans-Light'),
       url(https://fonts.gstatic.com/s/opensans/v16/mem5YaGs126MiZpBA-UN_r8OUuhs.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'),
       local('OpenSans-Regular'),
       url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFVZ0e.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'),
       local('OpenSans-SemiBold'),
       url(https://fonts.gstatic.com/s/opensans/v16/mem5YaGs126MiZpBA-UNirkOUuhs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Condensed Light'),
       local('OpenSansCondensed-Light'),
       url(https://fonts.gstatic.com/s/opensanscondensed/v13/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuXMQg.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Condensed Bold'),
       local('OpenSansCondensed-Bold'),
       url(https://fonts.gstatic.com/s/opensanscondensed/v13/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDuXMQg.ttf) format('truetype');
}
* {
  box-sizing: border-box;
}

.log-form .logo {
  max-width: 200px;
  margin: 60px auto 20px auto;
}

.log-form {
  padding: 20px;
  width: 40%;
  min-width: 320px;
  max-width: 362px;
  background: #3b424c;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

@media (max-width: 40em) {
  .log-form {
    width: 95%;
    position: relative;
    margin: 2.5% auto 0 auto;
    left: 0%;
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    -o-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
.log-form form {
  display: block;
  width: 100%;
  padding: 2em;
}
.log-form h2 {
  color: #5d5d5d;
  font-family: 'open sans condensed';
  font-size: 1.35em;
  display: block;
  background: #808080;
  width: 100%;
  text-transform: uppercase;
  padding: 0.75em 1em 0.75em 1.5em;
  box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.05);
  border: 1px solid #1d1d1d;
  margin: 0;
  font-weight: 200;
}
.log-form input {
  background: none;
  border: 1px solid #707070;
  color: #757575;
  display: block;
  margin: auto auto;
  margin-bottom: 2em;
  padding: 0.4em 0;
  text-align: center;
  width: 100%;
}

.log-form input:focus {
  outline: none;
}

.log-form .btn {
  background: none;
  border: 1px solid #707070;
  color: #757575;
  cursor: pointer;
  display: block;
  margin: auto auto;
  margin-bottom: 2em;
  padding: 0.4em 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
.log-form .btn:hover {
  background: #23cad5;
}
.log-form .btn:active {
  background: #0000FF;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1);
}
.log-form .btn:focus {
  outline: none;
}
.log-form .forgot {
  border: 0;
  background: none;
  color: #33d3de;
  line-height: 0.5em;
  position: relative;
  text-decoration: none;
  font-size: 0.75em;
  margin: 0;
  padding: 0;
  width: 100%;
}
.log-form .forgot:hover {
  color: #1ba0a9;
}
.errormsg {
  border: 0;
  background: none;
  color: #ff1a1a;
  line-height: 0.5em;
  position: relative;
  text-decoration: none;
  font-size: 1em;
  margin: 0;
  padding: 40px;
  left: 15%;
  width: 100%;
}


