.modal_contain {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  z-index: 599;

  &.active {
    display: none;
  }
  
  .modal{
    position: absolute;
    max-width: 500px;
    width: 100%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background: #3b424c;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  }

  .open_workspace{
    width: 100%;
    color: #20AAAC;
  }

  h3 {
    padding-left: 10px;
  }

  .workspace_item {
    flex: 1 0 calc(100% - 35px)
  }  

  .workspace_item_contain {
    display: flex;
    flex-wrap: nowrap;
  }

  .delete_workspace {
    flex: 1 0 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 5px;
      width: 10px;
    }
  }

  .workspace_list {
    max-height: 300px;
    background: none;
    overflow: auto;
    margin: 0;
    padding: 0;
    list-style: none;

    li{
      padding: 0;
    }

    button {
      background: none;
      color: #fff;
      width: 100%;
      border-top: 1px solid #62676f;
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 5px 10px;
      text-align: left;
      line-height: 0;
    }
  }


  .modal_elements {
    display: flex;
    flex-wrap: nowrap;
    padding: 10px;
    
    input {
      border: 1px solid #62676f;
      color: #000;
      height: 24px;
      width: 100%;
      margin-right: 12px;
    } 

    .modal_checkbox {
      align-items: center;
      display: flex;
      width: 300px;

      input {
        flex: 1 0 20px;
        margin-right: 4px;
      }

      label {
        flex: 1 0 calc(100% - 20px);
      }
      
    }

      #saveworkspaceas{
      color: #000000;
      }
    button {
      border: none;
      cursor: pointer;
      border-radius: 12px;
      height: 24px;
      width: 100%;
      background: #20AAAC;
      color: #fff;
      font-weight: 700;
    }
  }


  .close_modal {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
      color: #fff;
      width: 100%;
      max-width: 10px;
    }
  }
}