.app_card {
  background: none;
  height: 100%;
  width: 100%;
}

.card_body {
  color:#fff;
  height: calc(100% - 26px);

  .react-grid-Cell {
    padding: 0;
  }

  .react-grid-Cell__value {
    padding: 0 8px 0 8px;
  }

  .label {
    color: #fff;
  }
}

.react-grid-Toolbar {
  background: none !important;
  border: none !important; 
  height: 30px !important;
  padding: 5px !important;
}

.react-grid-Main, .react-grid-Grid {
  background: none !important;
  border: none !important;
  outline: none !important;
}

.react-grid-Toolbar .tools {
  margin: 0 5px 0 0 !important;
}

.react-grid-Toolbar .btn {
  background: none !important;
  height: 21px !important;
  padding: 0 5px !important;
  font-size: 1.2rem !important;
}

.react-grid-Header {
  border-top: 1px solid #68677C;
  border-bottom: 1px solid #68677C;
  box-shadow: none !important;
  background: none !important; 

  input {
    border: 1px solid #62676f;
    color: #fff;
    height: 24px;
    outline: none;
    width: 100%;
  }

  select {
    border: 1px solid #62676f;
    border-radius: 0;
    color: #fff;
    height: 24px;
  }
}

.react-grid-HeaderCell-sortable {
  overflow: hidden;
}

.react-grid-HeaderCell {
  background: #323841 !important;
  color: #fff !important;
}

.app_card_header {
  align-items: center;
  background: #434b57;
  border-bottom: 1px solid #68677C;
  border-top-width: 3px;
  border-top-color: #fff;
  border-top-style: solid;
  display: flex;
  height: 26px;
  width: 100%;


  h3 {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    padding-left: 8px;
  }

  .buttons_contain {
    display: flex;
    flex-wrap: nowrap;
    margin-left: auto;


  }



  button {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    height: 26px;
    justify-content: center;
    width: 26px;
    font-size: 12px;
    color: #fff;

    &:hover {
      svg{
        color: #20AAAC;
      }
    }
    svg {
      color: inherit; 
      max-width: 10px;
      transition: color 0.2s ease;
      width: 100%;
    }
  }
}